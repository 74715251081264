<template>
  <GumTooltip :disabled="!tooltip" :title="`${localTime} in ${timezone}`" class="cursor-help">
    {{ utcTime }}
  </GumTooltip>
</template>

<script setup lang="ts">
import dayjs from 'dayjs/esm'
import { computed } from 'vue'
import { GumTooltip } from '.'

const props = withDefaults(
  defineProps<{
    time: string
    format?: string
    tooltip?: boolean
  }>(),
  {
    format: 'D MMM YYYY HH:mm:ss',
    tooltip: true
  }
)

const timezone = dayjs.tz.guess()
const localTime = computed(() => dayjs.utc(props.time).tz(timezone).format(props.format))
const utcTime = computed(() => dayjs.utc(props.time).format(props.format))
</script>
